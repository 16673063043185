import React from "react";
import { connect } from "react-redux";
import {
  IDropdownOption,
  Label,
  TextField,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Checkbox,
  Modal,
} from "@fluentui/react";
import { IDropdownPropsCustom } from "../../common/dropdown/models/IDropdown";
import Dropdown from "../../common/dropdown/dropdown.controller";
import "./new-connection.scss";
import {
  fetchConnectionTypes,
  saveConnectionToDB,
  fetchAuthenticationOptions,
  validateConnection,
  updateConnection,
  clearValidatePropMsgDetails,
} from "../../../redux/actions/new-connection-action";
import {
  IAzureSqlConnection,
  ISqlServerConnection,
  IAzureDataLakeStorageGen2,
  ISparkConnection,
  IMsgInfo,
  IFileSystemConnection,
  IExcelConnection,
  IAzureFunctionConnection,
  IJarConnection,
  IParquetConnection,
  ISynapseConnection,
} from "./model/IConnection";
import { ConnectionAuthenticationOptions } from "../../../api/connectionAPI";
import { getDeepCopy } from "../../../util/javascript-functions";
import { Loading } from "../../loading/loading";
import { startGetAllConnections1 } from "./source-onboarding.service";
import * as TelemetryProvider from "../../../TelemetryProvider";
import {
  newConnectionClearButtonClickEvent,
  newConnectionSaveButtonClickEvent,
  newConnectionValidateButtonClickEvent,
} from "./source-onboarding.telemtetry-constants";
import {
  contentStyles,
  iconButtonStyles,
} from "../../common/modal/modal.style";
import IconButton from "../../common/button/icon-button/icon-button.controller";
import { AuthenticationTypes } from "../../../enum/authenticationType";

export interface INewConnectionState {
  ConnectionId?: number;
  DataSourceName?: string;
  ConnectionType?: string;
  AuthType?: string;
  AzureSqlConnection: IAzureSqlConnection;
  SqlServerConnection: ISqlServerConnection;
  AzureDataLakeStorageGen2: IAzureDataLakeStorageGen2;
  SparkConnection: ISparkConnection;
  FileSystemConnection: IFileSystemConnection;
  ExcelConnection: IExcelConnection;
  JarConnection: IJarConnection;
  ParquetConnection: IParquetConnection;
  AzureFunctionConnection: IAzureFunctionConnection;
  SynapseConnection: ISynapseConnection;
  ActionType?: string;
  AuthenticationOption?: string;
  FiscalYear?: number;
  WorkspaceURL?: string;
}

interface INewConnectionProps {
  DataSourceName?: string;
  ConnectionId?: number;
  ActionType?: string;
  AuthType?: string;
  ConnectionType?: string;
  authenticationOptionsList: ConnectionAuthenticationOptions[];
  connectionTypesList: IDropdownOption[];
  AzureSqlConnection: IAzureSqlConnection;
  SqlServerConnection: ISqlServerConnection;
  //saveMsg: IMsgInfo;
  validateMsg: IMsgInfo;
  //updateMsg: IMsgInfo;
  AuthenticationOption: string;
  //FiscalYear: number,
  AzureDataLakeStorageGen2: IAzureDataLakeStorageGen2;
  SparkConnection: ISparkConnection;
  FileSystemConnection: IFileSystemConnection;
  ExcelConnection: IExcelConnection;
  JarConnection: IJarConnection;
  ParquetConnection: IParquetConnection;
  AzureFunctionConnection: IAzureFunctionConnection;
  SynapseConnection: ISynapseConnection;
  tempConns: any;
  getConnectionTypes: () => void;
  getAuthenticationOptions: () => void;
  saveConnection: (newConnState: INewConnectionState, tempConns: any) => void;
  validateConnection: (newConnState: INewConnectionState) => void;
  updateConnection: (
    newConnState: INewConnectionState,
    ConnId: number,
    tempConns: any
  ) => void;
  clearValidateMsgInfo: (loading: boolean) => void;
  //  clearSaveMsgInfo: (msgInfo: IMsgInfo, loading: boolean) => void;
  // clearUpdateMsgInfo: (msgInfo: IMsgInfo, loading: boolean) => void;
  fiscalYear: number;
  showModal: boolean;
  updateIsEditModalVisible: any;
}

class NewConnection extends React.Component<
  INewConnectionProps,
  INewConnectionState,
  any
> {
  constructor(props: any) {
    super(props);
    this.state = {
      ConnectionId: this.props.ConnectionId,
      FiscalYear: this.props.fiscalYear,
      DataSourceName: this.props.DataSourceName,
      ActionType: this.props.ActionType,
      AuthType: this.props.AuthType,
      AuthenticationOption: this.props.AuthenticationOption,
      ConnectionType: this.props.ConnectionType,
      AzureSqlConnection: this.props.AzureSqlConnection,
      SqlServerConnection: this.props.SqlServerConnection,
      FileSystemConnection: this.props.FileSystemConnection,
      ExcelConnection: this.props.ExcelConnection,
      AzureDataLakeStorageGen2: this.props.AzureDataLakeStorageGen2,
      SparkConnection: this.props.SparkConnection,
      JarConnection: this.props.JarConnection,
      SynapseConnection: this.props.SynapseConnection,
      ParquetConnection: this.props.ParquetConnection,
      AzureFunctionConnection: this.props.AzureFunctionConnection,
    };
    if (this.props.ActionType === "edit" || this.props.ActionType === "clone") {
      {
        this.clearValidatePropsData(false);
      }
    }
    this.props.getConnectionTypes();
    this.props.getAuthenticationOptions();
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentWillUnmount() {
    this.clearValidatePropsData(false);
  }

  private _onDropDownFiscalyearValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const FiscalYear = Number(item == null ? "" : item.text);
    this.setState({
      FiscalYear,
    });
  };

  private _onDropDownConnectionTypesValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const ConnectionType = item == null ? "" : item.text;
    this.setState({
      ConnectionType,
      AzureSqlConnection: {
        ServerName: "",
        DatabaseName: "",
        UserName: "",
        Password: "",
        ServicePrincipalId: "",
        ServicePrincipalKey: "",
      },
      SqlServerConnection: {
        ServerName: "",
        DatabaseName: "",
        UserName: "",
        Password: "",
      },
      AzureDataLakeStorageGen2: {
        LakeStorageAccountName: "",
        ContainerName: "",
        ServicePrincipalId: "",
        ServicePrincipalKey: "",
      },
      SparkConnection: {
        Domain: "",
        ClusterID: "",
        AccessToken: "",
      },
      FileSystemConnection: {
        FilePath: "",
        Host: "",
        UserName: "",
        Password: "",
      },
      ExcelConnection: {
        Host: "",
        FileName: "",
        FirstRowAsHeader: false,
        SheetName: "",
        UserName: "",
        Password: "",
        LakeStorageAccountName: "",
        Directory: "",
        ServicePrincipalId: "",
        ServicePrincipalKey: "",
        AuthenticationMethod: "Basic",
        RowDelimeter: "",
        ColumnDelimeter: ""
      },
      JarConnection: {
        Domain: "",
        AccessToken: "",
        NewClusterDriverNodeType: "",
        NewClusterNodeType: "",
        NewClusterNumOfWorker: "",
        NewClusterVersion: "",
      },
      ParquetConnection: {
        LakeStorageAccountName: "",
        ContainerName: "",
        folderpath: "",
        filename: undefined,
        ServicePrincipalId: "",
        ServicePrincipalKey: "",
      },
      AzureFunctionConnection: {
        FunctionAppURL: "",
        FunctionKey: "",
      },
      SynapseConnection: {
        WorkspaceURL: "",
      }
    });
  };

  private _onDataSourceNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const DataSourceName = newValue;
    this.setState({
      DataSourceName,
    });
  };

  private _onAzureSqlServerNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { AzureSqlConnection } = this.state;
    AzureSqlConnection.ServerName = newValue;
    this.setState({
      AzureSqlConnection,
    });
  };
  private _onAzureSqlDatabaseNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { AzureSqlConnection } = this.state;
    AzureSqlConnection.DatabaseName = newValue;
    this.setState({
      AzureSqlConnection,
    });
  };

  private _onSqlServerServerNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { SqlServerConnection } = this.state;
    SqlServerConnection.ServerName = newValue;
    this.setState({
      SqlServerConnection,
    });
  };
  private _onSqlServerDatabaseNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { SqlServerConnection } = this.state;
    SqlServerConnection.DatabaseName = newValue;
    this.setState({
      SqlServerConnection,
    });
  };

  private _onLakeAccountNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { AzureDataLakeStorageGen2 } = this.state;
    AzureDataLakeStorageGen2.LakeStorageAccountName = newValue;
    this.setState({
      AzureDataLakeStorageGen2,
    });
  };
  private _onLakeContainerNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { AzureDataLakeStorageGen2 } = this.state;
    AzureDataLakeStorageGen2.ContainerName = newValue;
    this.setState({
      AzureDataLakeStorageGen2,
    });
  };
  private _onLakeTenantIdChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { AzureDataLakeStorageGen2 } = this.state;
    this.setState({
      AzureDataLakeStorageGen2,
    });
  };

  private _onSparkDomainChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { SparkConnection } = { ...this.state };
    SparkConnection.Domain = newValue;
    this.setState({
      SparkConnection,
    });
  };
  private _onSparkClusterIdChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { SparkConnection } = { ...this.state };
    SparkConnection.ClusterID = newValue;
    this.setState({
      SparkConnection,
    });
  };

  private _onSparkAccessTokenChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { SparkConnection } = { ...this.state };
    SparkConnection.AccessToken = newValue;
    this.setState({
      SparkConnection,
    });
  };

  private _onDropDownAuthenticationOptionsValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
    index?: number
  ): void => {
    const AuthenticationOption = item == null ? "" : item.text;
    this.setState({
      AuthenticationOption,
    });
  };

  private _onDropDownAuthTypeValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption,
    index?: number
  ): void => {
    const AuthType = item == null ? "" : item.text;
    this.setState({
      AuthType,
    });
  };

  private _onFilePathChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { FileSystemConnection } = this.state;
    FileSystemConnection.FilePath = newValue;
    this.setState({
      FileSystemConnection,
    });
  };

  private _onDropDownExcelAuthMethodsChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const { ExcelConnection } = this.state;
    const AuthMethod = item == null ? "" : item.key + "";
    ExcelConnection.AuthenticationMethod = AuthMethod;
    this.setState({
      ExcelConnection,
    });
  }

  private _onExcelPathChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { ExcelConnection } = this.state;
    ExcelConnection.Host = newValue;
    this.setState({
      ExcelConnection,
    });
  };

  private _onExcelFileNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { ExcelConnection } = this.state;
    ExcelConnection.FileName = newValue;
    this.setState({
      ExcelConnection,
    });
  };

  private _onExcelSheetNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const { ExcelConnection } = this.state;
    ExcelConnection.SheetName = newValue;
    this.setState({
      ExcelConnection,
    });
  };

  private _onExcelFirstRowHasHeaderChange = (
    ev?: React.FormEvent<HTMLElement>,
    isChecked?: boolean
  ) => {
    const { ExcelConnection } = this.state;
    if (isChecked != null) {
      ExcelConnection.FirstRowAsHeader = isChecked;
    }
    this.setState({
      ExcelConnection,
    });
  };

  private _validatebuttonClicked = (): void => {
    TelemetryProvider._trackEvent(newConnectionValidateButtonClickEvent);
    //this.clearUpdatePropsData(false);
    //this.clearSavePropsData(false);
    var validate = this.validateFields();
    if (validate) {
      if (
        this.props.ActionType !== "edit" &&
        this.state.DataSourceName != undefined &&
        this.state.DataSourceName.length > 40
      ) {
        alert("Data Source name must not exceed the limit of 40 characters.");
        return;
      }
      this.clearValidatePropsData(true);
      this.props.validateConnection(this.state);
    } else if (!validate) {
      alert("Please enter all the mandatory fields");
      return;
    }
  };

  private _cancelButtonClicked = (): void => {
    TelemetryProvider._trackEvent(newConnectionClearButtonClickEvent);
    {
      this.clearValidatePropsData(false);
      //this.clearUpdatePropsData(false);
      //this.clearSavePropsData(false);
    }
    this.setState({
      DataSourceName:
        this.props.ActionType === "edit" ? this.props.DataSourceName : "",
      ActionType: "",
      AuthType: "",
      AuthenticationOption: "",
      FiscalYear: this.props.fiscalYear,
      ConnectionType:
        this.props.ActionType === "edit" ? this.props.ConnectionType : "",
      AzureSqlConnection: {},
      SqlServerConnection: {},
      AzureDataLakeStorageGen2: {},
      FileSystemConnection: {},
      ExcelConnection: {},
      SparkConnection: {},
      SynapseConnection: {},
    });
  };

  private _saveButtonClicked = (): void => {
    TelemetryProvider._trackEvent(newConnectionSaveButtonClickEvent);
    var validate = this.validateFields();
    if (validate) {
      if (this.props.ActionType === "edit") {
        this.clearValidatePropsData(true);
        this.props.updateConnection(
          this.state,
          this.props.ConnectionId != undefined ? this.props.ConnectionId : 0,
          this.props.tempConns
        );
      } else {
        if (
          this.state.DataSourceName != undefined &&
          this.state.DataSourceName.length > 40
        ) {
          alert("Data Source name must not exceed the limit of 40 characters.");
          return;
        }
        this.clearValidatePropsData(true);
        this.props.saveConnection(this.state, this.props.tempConns);
      }
    } else if (!validate) {
      // this.clearValidatePropsData(true);
      alert("Please enter all the mandatory fields.");
      return;
    }
  };

    private validateFields = () => {
    var IsValidString = (x: any): Boolean => x !== undefined && x.trim("") !== "" && x !== null;

        if (
            this.state.ConnectionType === undefined ||
            this.state.ConnectionType === "" ||
            this.state.DataSourceName === undefined ||
            this.state.DataSourceName === ""
        ) {
            return false;
        } else if (this.state.ConnectionType !== undefined) {
            if (this.state.ConnectionType === "Azure SQL Database") {
                if (
                    this.state.AzureSqlConnection.ServerName === undefined ||
                    this.state.AzureSqlConnection.DatabaseName === undefined ||
                    this.state.AuthType === undefined ||
                    ((this.state.AuthType === AuthenticationTypes.SqlAuth || this.state.AuthType === AuthenticationTypes.AADAuth) &&
                    this.state.AuthenticationOption === undefined) ||
                    (this.state.AuthType === AuthenticationTypes.ServicePrincipalAuth &&
                        (!IsValidString(this.state.AzureSqlConnection.ServicePrincipalId) &&
                            !IsValidString(this.state.AzureSqlConnection.ServicePrincipalKey)))
                ) {
                    return false;
                }
            } else if (this.state.ConnectionType === "SQL Server") {
                if (
                    this.state.SqlServerConnection.ServerName === undefined ||
                    this.state.SqlServerConnection.DatabaseName === undefined ||
                    this.state.AuthType === undefined ||
                    this.state.AuthenticationOption === undefined ||
                    this.state.AuthenticationOption === ""
                ) {
                    return false;
                }
            } else if (this.state.ConnectionType === "Azure Data Lake Storage Gen2") {

                var isAzureDataLakeStorageGen2ContainerAccountNameValid =
                    IsValidString(this.state.AzureDataLakeStorageGen2.ContainerName) &&
                    IsValidString(this.state.AzureDataLakeStorageGen2.LakeStorageAccountName) &&
                    IsValidString(this.state.AuthType)

                var isAzureDataLakeStorageGen2ServicePrincipalValid =
                    IsValidString(this.state.AzureDataLakeStorageGen2.ServicePrincipalId) &&
                    IsValidString(this.state.AzureDataLakeStorageGen2.ServicePrincipalKey)

                return (this.state.AuthType === "System Assigned Managed Identity" && isAzureDataLakeStorageGen2ContainerAccountNameValid) ||
                    (this.state.AuthType === "Service Principal" && isAzureDataLakeStorageGen2ContainerAccountNameValid && isAzureDataLakeStorageGen2ServicePrincipalValid)

            } else if (this.state.ConnectionType === "Spark") {
                if (
                    this.state.SparkConnection.Domain === undefined ||
                    this.state.SparkConnection.ClusterID === undefined ||
                    this.state.SparkConnection.AccessToken === undefined ||
                    this.state.SparkConnection.Domain?.trim() === "" ||
                    this.state.SparkConnection.ClusterID?.trim() === "" ||
                    this.state.SparkConnection.AccessToken?.trim() === ""
                ) {
                    return false;
                }
            } else if (this.state.ConnectionType == "File System") {
                if (
                    this.state.FileSystemConnection.FilePath === undefined ||
                    this.state.AuthenticationOption === undefined ||
                    this.state.AuthenticationOption === ""
                ) {
                    return false;
                }
            } else if (this.state.ConnectionType === "Excel") {

                var isExcelDirectoryAccountNameValid =
                    IsValidString(this.state.ExcelConnection.LakeStorageAccountName) &&
                    IsValidString(this.state.ExcelConnection.Directory)

                var isExcelServicePrincipalValid =
                    IsValidString(this.state.ExcelConnection.ServicePrincipalId) ||
                    IsValidString(this.state.ExcelConnection.ServicePrincipalKey)

                return (this.state.AuthType === "System Assigned Managed Identity" && isExcelDirectoryAccountNameValid) ||
                    (this.state.AuthType === "Service Principal" && isExcelServicePrincipalValid && isExcelDirectoryAccountNameValid)
            }

            else if (this.state.ConnectionType == "JAR") {
                if (
                    this.state.JarConnection.Domain === undefined ||
                    this.state.JarConnection.AccessToken === undefined ||
                    this.state.JarConnection.NewClusterNodeType === undefined ||
                    this.state.JarConnection.NewClusterDriverNodeType === undefined ||
                    this.state.JarConnection.NewClusterNumOfWorker === undefined ||
                    this.state.JarConnection.NewClusterVersion === undefined ||
                    this.state.JarConnection.Domain?.trim() === "" ||
                    this.state.JarConnection.AccessToken?.trim() === "" ||
                    this.state.JarConnection.NewClusterNodeType?.trim() === "" ||
                    this.state.JarConnection.NewClusterDriverNodeType?.trim() === "" ||
                    this.state.JarConnection.NewClusterNumOfWorker?.trim() === "" ||
                    this.state.JarConnection.NewClusterVersion?.trim() === ""
                ) {
                    return false;
                }
            }
            else if (this.state.ConnectionType == "Synapse Workspace") {
                if (
                    this.state.SynapseConnection.WorkspaceURL === undefined ||
                    this.state.SynapseConnection.WorkspaceURL?.trim() === ""
                ) {
                    return false;
                }
            }
            else if (this.state.ConnectionType === "parquet") {

                var isParquetAccountNameContainerNameValid =
                    IsValidString(this.state.ParquetConnection.LakeStorageAccountName) &&
                    IsValidString(this.state.ParquetConnection.ContainerName)

                var isParquetServicePrincipalValid =
                    IsValidString(this.state.ParquetConnection.ServicePrincipalId) ||
                    IsValidString(this.state.ParquetConnection.ServicePrincipalKey)

                return (this.state.AuthType === "System Assigned Managed Identity" && isParquetAccountNameContainerNameValid) ||
                    (this.state.AuthType === "Service Principal" && isParquetAccountNameContainerNameValid && isParquetServicePrincipalValid);
            }
            else if (this.state.ConnectionType == "Azure Function") {
                if (
                    this.state.AzureFunctionConnection.FunctionAppURL === undefined ||
                    this.state.AzureFunctionConnection.FunctionKey === undefined ||
                    this.state.AzureFunctionConnection.FunctionAppURL?.trim() === "" ||
                    this.state.AzureFunctionConnection.FunctionKey?.trim() === ""
                ) {
                    return false;
                }
            }
            return true;
        }
  };

  private handleDismiss() {
    this.props.updateIsEditModalVisible(false);
  }

  public render(): JSX.Element {
    const dropDownConnectionTypes: IDropdownPropsCustom = {
      placeholder: "Select a value",
      label: "Connection Type:",
      onChange: this._onDropDownConnectionTypesValueChange,
      options: this.props.connectionTypesList,
      required: true,
      selectedKey: this.state.ConnectionType,
      disabled: this.props.ActionType === "edit" ? true : false,
    };

    var FiscalyearList = [
      { key: 2020, text: "2020" },
      { key: 2021, text: "2021" },
    ];

    const dropDownFiscalYear: IDropdownPropsCustom = {
      placeholder: "Select a value",
      label: "Fiscal Year:",
      onChange: this._onDropDownFiscalyearValueChange,
      options: FiscalyearList,
      required: true,
      selectedKey: this.state.FiscalYear,
      disabled: this.props.ActionType === "edit" ? true : false,
    };

    return (
      <Modal
        isOpen={this.props.showModal}
        //onDismiss={this.handleDismiss}
        containerClassName={contentStyles.container}
        isBlocking={true}
      >
        <div className={contentStyles.header}>
          <span id="preview">Create/Edit DataSource</span>
          <IconButton
            styles={iconButtonStyles}
            iconName="Cancel"
            title="Close Modal"
            fontSize={12}
            ariaLabel="Close popup modal"
            onClick={this.handleDismiss}
          />
        </div>
        <div id="children" className={contentStyles.body}>
          <div className="new-connection">
            <div className="validation-warning-banner">
              <span>WARNING: Validate button will save your changes.</span>
            </div>
            <div className="new-connection-buttons-list">
              <PrimaryButton
                text="Clear"
                onClick={this._cancelButtonClicked}
                className="header-buttons"
                style={{ backgroundColor: "#288560" }}
              />
              <PrimaryButton
                text="Save"
                onClick={this._saveButtonClicked}
                className="header-buttons"
                style={{ backgroundColor: "#288560" }}
              />
              <PrimaryButton
                text="Validate"
                onClick={this._validatebuttonClicked}
                className="header-buttons"
                style={{ backgroundColor: "#288560" }}
              />
            </div>
            {this.getMsgDetails(
              this.props.validateMsg != undefined ? this.props.validateMsg : {}
            )}
            <Label required={true}>DataSource Name:</Label>
            <TextField
              value={this.state.DataSourceName}
              disabled={this.props.ActionType === "edit" ? true : false}
              onChange={this._onDataSourceNameChange}
              ariaLabel={"DataSource Name"}
            />{" "}
            <div className="new-connection-dropdown">
              <Dropdown {...dropDownConnectionTypes} />
            </div>
            {this.getConnDetails()}
          </div>
        </div>
      </Modal>
    );
  }

  private clearValidatePropsData(loading: boolean) {
    this.props.clearValidateMsgInfo(loading);
  }

  private getMsgDetails = (msg: IMsgInfo) => {
    return (
      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
        {msg.loading ? (
          <div>
            <Spinner size={SpinnerSize.large} />
          </div>
        ) : (
          ""
        )}
        {msg != undefined && msg.Result != undefined ? (
          msg.Result === 1 && msg.StatusCode === 200 ? (
            <span style={{ color: "green" }}>{msg.ErrorMessage} </span>
          ) : msg.Result === 0 ? (
            <span style={{ color: "red" }}>{msg.ErrorMessage} </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  };

  private getAuthOptions = (
    authOptions: IDropdownOption[],
    ConnectionType: string,
    AuthType: string,
    hasAuthType: boolean
  ) => {
    authOptions = [];
    const totAuthOptions = this.props.authenticationOptionsList;
    if (totAuthOptions != undefined) {
      for (var i = 0; i < totAuthOptions.length; i++) {
        if (
          hasAuthType &&
          totAuthOptions[i].ConnectionTypeName +
            "-" +
            totAuthOptions[i].AuthType ===
            ConnectionType + "-" + AuthType
        ) {
          for (var j = 0; j < totAuthOptions[i].Credentials.length; j++) {
            authOptions.push({
              key: totAuthOptions[i].Credentials[j],
              text: totAuthOptions[i].Credentials[j],
            });
          }
          break;
        } else if (
          !hasAuthType &&
          totAuthOptions[i].ConnectionTypeName === ConnectionType
        ) {
          for (var j = 0; j < totAuthOptions[i].Credentials.length; j++) {
            authOptions.push({
              key: totAuthOptions[i].Credentials[j],
              text: totAuthOptions[i].Credentials[j],
            });
          }
          break;
        }
      }
    }
    return authOptions;
  };

  private getConnDetails = () => {
    const ConnectionType = this.state.ConnectionType;
    const AuthType =
          this.state.AuthType != undefined ? this.state.AuthType : "";
    var connectionDetails;
    var authTypes;
    var authOptions: any;
    var authTypeConnectionDetails: any;

    if (ConnectionType === "Azure SQL Database") {
      connectionDetails = (
        <div>
          <Label required={true} className="onboarding-source-label">
            Server Name:
          </Label>
              <TextField
            value={this.state.AzureSqlConnection.ServerName}
            onChange={this._onAzureSqlServerNameChange}
            ariaLabel={"Server Name"}
          />
          <Label required={true} className="onboarding-source-label">
            Database Name:
          </Label>
              <TextField
            value={this.state.AzureSqlConnection.DatabaseName}
            onChange={this._onAzureSqlDatabaseNameChange}
            ariaLabel={"Database Name"}
          />
        </div>
      );

      authTypes = [
        { key: AuthenticationTypes.AADAuth, text: AuthenticationTypes.AADAuth },
        { key: AuthenticationTypes.SqlAuth, text: AuthenticationTypes.SqlAuth },
        { key: AuthenticationTypes.SystemAssignedAuth, text: AuthenticationTypes.SystemAssignedAuth},
        { key: AuthenticationTypes.ServicePrincipalAuth, text: AuthenticationTypes.ServicePrincipalAuth}
      ];

      if (AuthType !== AuthenticationTypes.SystemAssignedAuth && AuthType !== AuthenticationTypes.ServicePrincipalAuth){
          authOptions = this.getAuthOptions(
            authOptions,
            ConnectionType,
            AuthType,
            true
          );
        }
      else {
          authOptions = null;
       }
        if (AuthType === AuthenticationTypes.ServicePrincipalAuth) {
            authTypeConnectionDetails = (
                <div>
                    <Label required={true}>Service Principal ID:</Label>
                    <TextField
                        value={this.state.AzureSqlConnection.ServicePrincipalId}
                        onChange={(event, newValue) => {
                            var { AzureSqlConnection } = this.state;
                            AzureSqlConnection.ServicePrincipalId = newValue;
                            this.setState({ AzureSqlConnection });
                        }}
                        ariaLabel={"Service Principal ID"}
                    />
                    <Label required={true}>Service Principal Key:</Label>
                    <TextField
                        value={this.state.AzureSqlConnection.ServicePrincipalKey}
                        onChange={(event, newValue) => {
                            var { AzureSqlConnection } = this.state;
                            AzureSqlConnection.ServicePrincipalKey = newValue;
                            this.setState({ AzureSqlConnection });
                        }}
                        ariaLabel={"Service Principal Key"}
                    />
                </div>
            );
      }
    } else if (ConnectionType === "SQL Server") {
      connectionDetails = (
        <div>
          <Label required={true}>Server Name:</Label>
          <TextField
            value={this.state.SqlServerConnection.ServerName}
            onChange={this._onSqlServerServerNameChange}
            ariaLabel={"Server Name"}
          />
          <Label required={true}>Database Name:</Label>
          <TextField
            value={this.state.SqlServerConnection.DatabaseName}
            onChange={this._onSqlServerDatabaseNameChange}
            ariaLabel={"Database Name"}
          />
        </div>
      );

      authTypes = [
        { key: "WindowsAuth", text: "WindowsAuth" },
        { key: "SqlAuth", text: "SqlAuth" },
      ];

      authOptions = this.getAuthOptions(
        authOptions,
        ConnectionType,
        AuthType,
        true
      );
    } else if (ConnectionType === "Azure Data Lake Storage Gen2") {

      authTypes = [
            { key: AuthenticationTypes.SystemAssignedAuth, text: AuthenticationTypes.SystemAssignedAuth },
            { key: AuthenticationTypes.ServicePrincipalAuth, text: AuthenticationTypes.ServicePrincipalAuth }
        ];

      connectionDetails = (
        <div>
          <Label required={true}>Lake Storage Account Name:</Label>
          <TextField
            value={this.state.AzureDataLakeStorageGen2.LakeStorageAccountName}
            onChange={this._onLakeAccountNameChange}
            ariaLabel={"Lake Storage Account Name"}
          />
          <Label required={true}>Container Name:</Label>
          <TextField
            value={this.state.AzureDataLakeStorageGen2.ContainerName}
            onChange={this._onLakeContainerNameChange}
            ariaLabel={"Container Name"}
              />
          </div>
        );

        if (this.state.AuthType === "Service Principal") {
            authTypeConnectionDetails = (
                <div>
                    <Label required={true}>Service Principal ID:</Label>
                    <TextField
                        value={this.state.AzureDataLakeStorageGen2.ServicePrincipalId}
                        onChange={(event, newValue) => {
                            var { AzureDataLakeStorageGen2 } = this.state;
                            AzureDataLakeStorageGen2.ServicePrincipalId = newValue;
                            this.setState({ AzureDataLakeStorageGen2 });
                        }}
                        ariaLabel={"Service Principal ID"}
                    />
                    <Label required={true}>Service Principal Key:</Label>
                    <TextField
                        value={this.state.AzureDataLakeStorageGen2.ServicePrincipalKey}
                        onChange={(event, newValue) => {
                            var { AzureDataLakeStorageGen2 } = this.state;
                            AzureDataLakeStorageGen2.ServicePrincipalKey = newValue;
                            this.setState({ AzureDataLakeStorageGen2 });
                        }}
                        ariaLabel={"Service Principal Key"}
                    />
                </div>
            );
    }    
      //authOptions = this.getAuthOptions(authOptions, ConnectionType, AuthType, false);
    } else if (ConnectionType === "File System") {
      connectionDetails = (
        <div>
          <Label required={true}>
            Host Path:(e.g.\\machinename\foldername\)
          </Label>
          <TextField
            value={this.state.FileSystemConnection.FilePath}
            onChange={this._onFilePathChange}
            ariaLabel={"Host Path"}
          />
        </div>
      );

      authOptions = this.getAuthOptions(
        authOptions,
        ConnectionType,
        AuthType,
        false
      );
    } else if (ConnectionType === "Excel") {

        authTypes = [
            { key: AuthenticationTypes.SystemAssignedAuth, text: AuthenticationTypes.SystemAssignedAuth },
            { key: AuthenticationTypes.ServicePrincipalAuth, text: AuthenticationTypes.ServicePrincipalAuth }
        ];

        connectionDetails = (
          <div>
            <Label required={true}> Lake Storage Account Name: </Label>
            <TextField
              value={this.state.ExcelConnection.LakeStorageAccountName}
              onChange={(event, newValue) => {
                var { ExcelConnection } = this.state;
                ExcelConnection.LakeStorageAccountName = newValue;
                this.setState({ ExcelConnection });
              }}
              ariaLabel={"Lake Storage Account Name"}
            />
            <Label required={true}> Directory: </Label>
            <TextField
              value={this.state.ExcelConnection.Directory}
              onChange={(event, newValue) => {
                var { ExcelConnection } = this.state;
                ExcelConnection.Directory = newValue;
                this.setState({ ExcelConnection });
              }}
              ariaLabel={"Directory"}
            />
            <Label required={true}>FileName:</Label>
            <TextField
              value={this.state.ExcelConnection.FileName}
              onChange={this._onExcelFileNameChange}
              ariaLabel={"FileName"}
            />
            <Label required={true}>Sheet Name:</Label>
            <TextField
              value={this.state.ExcelConnection.SheetName}
              onChange={this._onExcelSheetNameChange}
              ariaLabel={"Sheet Name"}
            />
            <Label>First Row Has Header:</Label>
            <Checkbox
              checked={this.state.ExcelConnection.FirstRowAsHeader}
              onChange={this._onExcelFirstRowHasHeaderChange}
              indeterminate={false}
              ariaLabel={"First Row has header"}
              className=""
              disabled={false}
              boxSide="end"
            />
            <Label>Row Delimeter:</Label>
            <TextField
              value={this.state.ExcelConnection.RowDelimeter}
              onChange={(event, newValue) => {
                var { ExcelConnection } = this.state;
                ExcelConnection.RowDelimeter = newValue;
                this.setState({ ExcelConnection });
              }}
              ariaLabel={"Row Delimeter"}
            />
            <Label>Column Delimeter:</Label>
            <TextField
              value={this.state.ExcelConnection.ColumnDelimeter}
              onChange={(event, newValue) => {
                var { ExcelConnection } = this.state;
                ExcelConnection.ColumnDelimeter = newValue;
                this.setState({ ExcelConnection });
              }}
              ariaLabel={"Column Delimeter"}
            />
            
          </div>
        );

        if (this.state.AuthType === "Service Principal") {
            authTypeConnectionDetails = (
                <div>

                    <Label required={true}>Service Principal ID:</Label>
                    <TextField
                        value={this.state.ExcelConnection.ServicePrincipalId}
                        onChange={(event, newValue) => {
                            var { ExcelConnection } = this.state;
                            ExcelConnection.ServicePrincipalId = newValue;
                            this.setState({ ExcelConnection });
                        }}
                        ariaLabel={"Service Principal ID"}
                    />
                    <Label required={true}>Service Principal Key:</Label>
                    <TextField
                        value={this.state.ExcelConnection.ServicePrincipalKey}
                        onChange={(event, newValue) => {
                            var { ExcelConnection } = this.state;
                            ExcelConnection.ServicePrincipalKey = newValue;
                            this.setState({ ExcelConnection });
                        }}
                        ariaLabel={"Service Principal Key"}
                    />
                </div>
            )
        }
      
    } else if (ConnectionType === "Spark") {
      connectionDetails = (
        <div>
          <Label required={true}>Domain:</Label>
          <TextField
            value={this.state.SparkConnection.Domain}
            onChange={this._onSparkDomainChange}
            ariaLabel={"Domain"}
          />
          <Label required={true}>Existing Cluster ID:</Label>
          <TextField
            value={this.state.SparkConnection.ClusterID}
            onChange={this._onSparkClusterIdChange}
            ariaLabel={"Existing ClusterID"}
          />
          <Label required={true}>Access Token:</Label>
          <TextField
            value={this.state.SparkConnection.AccessToken}
            onChange={this._onSparkAccessTokenChange}
            ariaLabel={"Access Token"}
          />
        </div>
      );

      // authOptions = this.getAuthOptions(authOptions, ConnectionType, AuthType, false);
    }  else if (ConnectionType === "JAR") {
      connectionDetails = (
        <div>
          <Label required={true}>Domain:</Label>
          <TextField
            ariaLabel={"Domain"}
            value={this.state.JarConnection.Domain}
            onChange={(event, newValue) => {
              var { JarConnection } = this.state;
              JarConnection.Domain = newValue;
              this.setState({ JarConnection });
            }}
          />
          <Label required={true}>Access Token:</Label>
          <TextField
            ariaLabel={"Access Token"}
            value={this.state.JarConnection.AccessToken}
            onChange={(event, newValue) => {
              var { JarConnection } = this.state;
              JarConnection.AccessToken = newValue;
              this.setState({ JarConnection });
            }}
          />
          <Label required={true}>New Cluster Node Type:</Label>
          <TextField
            ariaLabel={"New Cluster Node Type"}
            value={this.state.JarConnection.NewClusterNodeType}
            onChange={(event, newValue) => {
              var { JarConnection } = this.state;
              JarConnection.NewClusterNodeType = newValue;
              this.setState({ JarConnection });
            }}            
          />
          <Label required={true}>New Cluster Driver Node Type:</Label>
          <TextField
            ariaLabel={"New Cluster Driver Node Type"}
            value={this.state.JarConnection.NewClusterDriverNodeType}
            onChange={(event, newValue) => {
              var { JarConnection } = this.state;
              JarConnection.NewClusterDriverNodeType = newValue;
              this.setState({ JarConnection });
            }}            
          />
          <Label required={true}>New Cluster Number Of Workers:</Label>
          <TextField
            ariaLabel={"New Cluster Number Of Workers"}
            value={this.state.JarConnection.NewClusterNumOfWorker}
            onChange={(event, newValue) => {
              var { JarConnection } = this.state;
              JarConnection.NewClusterNumOfWorker = newValue;
              this.setState({ JarConnection });
            }}            
          />
          <Label required={true}>New Cluster Version:</Label>
          <TextField
            ariaLabel={"New Cluster Version"}
            value={this.state.JarConnection.NewClusterVersion}
            onChange={(event, newValue) => {
              var { JarConnection } = this.state;
              JarConnection.NewClusterVersion = newValue;
              this.setState({ JarConnection });
            }}            
          />
        </div>
      );
      
    }  else if (ConnectionType === "Synapse Workspace") {
      connectionDetails = (
        <div>
          <Label required={true}>Workspace URL:</Label>
          <TextField
            ariaLabel={"Workspace URL"}
            value={this.state.SynapseConnection.WorkspaceURL}
            onChange={(event, newValue) => {
              var { SynapseConnection } = this.state;
              SynapseConnection.WorkspaceURL = newValue;
              this.setState({ SynapseConnection });
            }}
          />
        </div>
      );

    } else if (ConnectionType === "parquet") {

      authTypes = [
            { key: AuthenticationTypes.SystemAssignedAuth, text: AuthenticationTypes.SystemAssignedAuth },
            { key: AuthenticationTypes.ServicePrincipalAuth, text: AuthenticationTypes.ServicePrincipalAuth }
        ];

      connectionDetails = (
        <div>
          <Label required={true}>Lake Storage Account Name:</Label>
          <TextField
            ariaLabel={"Lake Storage Account Name"}
            value={this.state.ParquetConnection.LakeStorageAccountName}
            onChange={(event, newValue) => {
              var { ParquetConnection } = this.state;
              ParquetConnection.LakeStorageAccountName = newValue;
              this.setState({ ParquetConnection });
            }}            
          />
          <Label required={true}>Container Name:</Label>
          <TextField
            ariaLabel={"Container Name"}
            value={this.state.ParquetConnection.ContainerName}
            onChange={(event, newValue) => {
              var { ParquetConnection } = this.state;
              ParquetConnection.ContainerName = newValue;
              this.setState({ ParquetConnection });
            }}            
          />
          <Label>Folder Path:</Label>
          <TextField
            ariaLabel={"Folder Path"}
            value={this.state.ParquetConnection.folderpath}
            onChange={(event, newValue) => {
              var { ParquetConnection } = this.state;
              ParquetConnection.folderpath = newValue;
              this.setState({ ParquetConnection });
            }}            
          />
        </div>
        );

        if (this.state.AuthType === "Service Principal") {
            authTypeConnectionDetails = (
                <div>
                    <Label required={true}>Service Principal ID:</Label>
                    <TextField
                        ariaLabel={"Service Principal ID"}
                        value={this.state.ParquetConnection.ServicePrincipalId}
                        onChange={(event, newValue) => {
                            var { ParquetConnection } = this.state;
                            ParquetConnection.ServicePrincipalId = newValue;
                            this.setState({ ParquetConnection });
                        }}
                    />
                    <Label required={true}>Service Principal Key:</Label>
                    <TextField
                        ariaLabel={"Service Principal Key"}
                        value={this.state.ParquetConnection.ServicePrincipalKey}
                        onChange={(event, newValue) => {
                            var { ParquetConnection } = this.state;
                            ParquetConnection.ServicePrincipalKey = newValue;
                            this.setState({ ParquetConnection });
                        }}
                    />
                </div>
            )
        }

    }  else if (ConnectionType === "Azure Function") {
      connectionDetails = (
        <div>
          <Label required={true}>Function App URL:</Label>
          <TextField
            ariaLabel={"Function App URL"}
            value={this.state.AzureFunctionConnection.FunctionAppURL}
            onChange={(event, newValue) => {
              var { AzureFunctionConnection } = this.state;
              AzureFunctionConnection.FunctionAppURL = newValue;
              this.setState({ AzureFunctionConnection });
            }}          
          />
          <Label required={true}>Function Key:</Label>
          <TextField
            ariaLabel={"Function Key"}
            value={this.state.AzureFunctionConnection.FunctionKey}
            onChange={(event, newValue) => {
              var { AzureFunctionConnection } = this.state;
              AzureFunctionConnection.FunctionKey = newValue;
              this.setState({ AzureFunctionConnection });
            }}          
          />
        </div>
      );

    } else {
      connectionDetails = <div>Nothing found!</div>;
    }

    var dropDownAuthTypes;
    if (authTypes) {
      dropDownAuthTypes = {
        placeholder: "Select authentication type",
        label: "Authentication Type:",
        onChange: this._onDropDownAuthTypeValueChange,
        options: authTypes,
        required: true,
        selectedKey:
          this.state.AuthType !== undefined ? this.state.AuthType : "",
      };
    }

    var dropDownAuthOptions;
    if (authOptions != undefined) {
      dropDownAuthOptions = {
        placeholder: "Select from existing authentication options",
        label: "Authentication Options:",
        onChange: this._onDropDownAuthenticationOptionsValueChange,
        options: authOptions,
        required: true,
        selectedKey: this.state.AuthenticationOption,
      };
    }

    return (
      <div>
        {connectionDetails}
        {authTypes ? (
          <div style={{ margin: "10px 0px 10px 0px" }}>
            <Dropdown {...(dropDownAuthTypes as IDropdownPropsCustom)} />
          </div>
        ) : null}
        {authOptions ? (
          <div style={{ margin: "10px 0px 25px 0px" }}>
            <Dropdown {...(dropDownAuthOptions as IDropdownPropsCustom)} />
          </div>
            ) : null}
       {authTypeConnectionDetails}
      </div>
    );
  };
}

const mapStateToProps = (state: any, ownProps: any): any => {
  return {
    fiscalYear: state.fiscalYear.fiscalYear,
    connectionTypesList: state.connectionState.connectionTypesList,
    authenticationOptionsList: state.connectionState.authenticationOptionsList,
    //   saveMsg: state.connectionState.saveMsg,
    validateMsg: state.connectionState.validateMsg,
    //  updateMsg: state.connectionState.updateMsg,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getConnectionTypes: () => dispatch(fetchConnectionTypes()),
    clearValidateMsgInfo: (loading: boolean) =>
      dispatch(clearValidatePropMsgDetails(loading)),
    //  clearSaveMsgInfo: (msgInfo: IMsgInfo, loading: boolean) => dispatch(clearValidatePropMsgDetails(msgInfo, loading)),
    //  clearUpdateMsgInfo: (msgInfo: IMsgInfo, loading: boolean) => dispatch(clearValidatePropMsgDetails(msgInfo, loading)),

    saveConnection: (newConnState: INewConnectionState, tempConns: any) =>
      dispatch(saveConnectionToDB(newConnState, tempConns)),
    getAuthenticationOptions: () => dispatch(fetchAuthenticationOptions()),
    validateConnection: (newConnState: INewConnectionState) =>
      dispatch(validateConnection(newConnState)),
    updateConnection: (
      newConnState: INewConnectionState,
      ConnId: number,
      tempConns: any
    ) => dispatch(updateConnection(newConnState, ConnId, tempConns)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewConnection);
