export const Configurations = {

  //Release variables
  authority: "____authority____",
  enableFlighting: "____enableFlighting____",
  appInsightsEndpoint: "0",
  MsEnvironment: "____MsEnvironment____",
  environment: "release",
  mintHubApiBaseUrl: "____mintHubApiBaseUrl____",
  clientId: "____clientId____",
  scope: "____scope____",
  instrumentationKey: "03af4494-7e07-4696-ac62-03c3c858f046",
  ingestionUrl: "__ingestionUrl__",

  // ### COMMENT OUT THE ABOVE AND UNCOMMENT THE BELOW TO USE LOCALHOST, SANDBOX, OR UAT ###

  // Corp Dev API
  // authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
  // enableFlighting: "1",
  // appInsightsEndpoint: "0",
  // MsEnvironment: "Corp",
  // environment: "sandbox",
  // mintHubApiBaseUrl: "https://app-mintorchapi-dev.azurewebsites.net/api",
  // clientId: "0a044e52-c13c-4206-a883-8f8cf7c921cf",
  // scope: "88fd21bf-e531-49ae-ba4c-6354f4c26b25/readAccess",
  // instrumentationKey: "e579391f-9b26-4c63-8589-ce98ca3b0e13",
  // ingestionUrl: 'https://mintsystemconfig-dev.azurefd.net/system/entity-onboarding',

  // Corp UAT API
  // authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
  // enableFlighting: "1",
  // appInsightsEndpoint: "0",
  // MsEnvironment: "Corp",
  // environment: "uat",
  // mintHubApiBaseUrl: "https://app-mintorchapi-uat.azurewebsites.net/api",
  // clientId: "ce53de7e-d89c-42f8-b4ea-d7f79f7e7c4c",
  // scope: "686bc283-5e65-4c17-b14f-42c7be9363c8/readAccess",
  // instrumentationKey: "af2869eb-ec4b-4e7b-a238-186f813a6cb2",
  // ingestionUrl: 'https://mintsystemconfig-uat.azurefd.net/system/entity-onboarding',
  
  // Localhost API
  // authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
  // enableFlighting: "1",
  // appInsightsEndpoint: "0",
  // MsEnvironment: "Corp",
  // environment: "local",
  // mintHubApiBaseUrl: "https://localhost:44390/api",
  // clientId: "0a044e52-c13c-4206-a883-8f8cf7c921cf",
  // scope: "88fd21bf-e531-49ae-ba4c-6354f4c26b25/readAccess",
  // instrumentationKey: "e579391f-9b26-4c63-8589-ce98ca3b0e13",
  // ingestionUrl: 'https://mintsystemconfig.microsoft.com/system/entity-onboarding',  //PROD
  
  appInsightsKey: "",
  aadResource: "",
  aadClientId: "",
};
