import {
  FETCH_CONNECTION_TYPES_REQUEST,
  FETCH_CONNECTION_TYPES_SUCCESS,
  FETCH_CONNECTION_TYPES_FAILURE,
  FETCH_AUTHENTICATION_OPTIONS_REQUEST,
  FETCH_AUTHENTICATION_OPTIONS_SUCCESS,
  FETCH_AUTHENTICATION_OPTIONS_FAILURE,
  FETCH_CONNECTION_SAVE_SUCCESS,
  FETCH_CONNECTION_VALIDATION_SUCCESS,
  FETCH_CONNECTION_UPDATE_SUCCESS,
  FETCH_VALIDATE_MSGS,
  FETCH_UPDATE_MSGS,
  FETCH_SAVE_MSGS,
  FETCH_FAILURE,
} from "./actionTypes";
import {
  GetAllConnectionTypes,
  saveConnectionToDBAPI,
  GetAllAuthenticationOptions,
  ConnectionAuthenticationOptions,
  validateConnectionDetails,
  updateConnectionDetails,
} from "../../api/connectionAPI";
import * as types from "../types/connection-action-types";
import { INewConnectionState } from "../../components/onboarding/source-onboarding/new-connection";
import { IConnectionAPI, IConnectionAttribute } from "../../api/connectionAPI";
import { IMsgInfo } from "../../components/onboarding/source-onboarding/model/IConnection";
import { startGetAllConnections1 } from "../../components/onboarding/source-onboarding/source-onboarding.service";
import { AppActions } from "../types/app-actions";
import { Connection } from "../../models/connection";
import { IComboBoxOption } from "@fluentui/react";
import { AuthenticationTypes} from "../../enum/authenticationType";

const fetchConnectionTypesRequestAction = () => {
  return {
    type: FETCH_CONNECTION_TYPES_REQUEST,
  };
};

export const getConnectionList = (
  connectionList: IComboBoxOption[]
): AppActions => ({
  type: types.FETCH_ALL_CONNECTION_SUCCESS,
  connectionList,
});

export const fetchConnectionTypesSuccessAction = (connectionTypes: any) => {
  return {
    type: FETCH_CONNECTION_TYPES_SUCCESS,
    payload: connectionTypes,
  };
};

const fetchConnectionTypesFailureAction = (error: any) => {
  return {
    type: FETCH_CONNECTION_TYPES_FAILURE,
    payload: error,
  };
};

const fetchAuthenticationOptionsRequestAction = () => {
  return {
    type: FETCH_AUTHENTICATION_OPTIONS_REQUEST,
  };
};

export const fetchAuthenticationOptionsSuccessAction = (
  authenticationOptions: any
) => {
  return {
    type: FETCH_AUTHENTICATION_OPTIONS_SUCCESS,
    payload: authenticationOptions,
  };
};

const fetchSaveSuccessAction = (resultMsg: IMsgInfo) => {
  return {
    type: FETCH_CONNECTION_SAVE_SUCCESS,
    payload: resultMsg,
  };
};

const fetchValidateCleanSuccessAction = (resultMsg: IMsgInfo) => {
  return {
    type: FETCH_VALIDATE_MSGS,
    payload: resultMsg,
  };
};

const fetchUpdateCleanSuccessAction = (resultMsg: IMsgInfo) => {
  return {
    type: FETCH_UPDATE_MSGS,
    payload: resultMsg,
  };
};

const fetchSaveCleanSuccessAction = (resultMsg: IMsgInfo) => {
  return {
    type: FETCH_SAVE_MSGS,
    payload: resultMsg,
  };
};

const fetchValidateSuccessAction = (resultMsg: IMsgInfo) => {
  return {
    type: FETCH_CONNECTION_VALIDATION_SUCCESS,
    payload: resultMsg,
  };
};

const fetchUpdateSuccessAction = (resultMsg: IMsgInfo) => {
  return {
    type: FETCH_CONNECTION_UPDATE_SUCCESS,
    payload: resultMsg,
  };
};

const fetchFailureAction = (resultMsg: IMsgInfo) => {
  return {
    type: FETCH_FAILURE,
    payload: resultMsg,
  };
};

const fetchAuthenticationOptionsFailureAction = (error: any) => {
  return {
    type: FETCH_AUTHENTICATION_OPTIONS_FAILURE,
    payload: error,
  };
};

export const fetchConnectionTypes = () => {
  return (dispatch: any) => {
    //dispatch(fetchConnectionTypesRequestAction)
    GetAllConnectionTypes().then((response) => {
      if (response != undefined) {
        const cTypes = response.Result.map((connectionType: string, index: any) => {
          return {
            key: connectionType,
            text: connectionType,
          };
        });
        dispatch(fetchConnectionTypesSuccessAction(cTypes.sort((a: any, b: any)=> a.text.localeCompare(b.text))));
      }
    })
    .catch ((error) => {
      let tempResult: IMsgInfo = {};
      tempResult.Result = 0;
      tempResult.ErrorMessage = "Unable to fetch connection types."
      dispatch(fetchFailureAction(tempResult));
    });
  };
};

export const fetchAuthenticationOptions = () => {
  return (dispatch: any) => {
    // dispatch(fetchAuthenticationOptionsRequestAction)
    GetAllAuthenticationOptions().then((response) => {
      if (response != undefined) {
        const connAuthOptions1: ConnectionAuthenticationOptions[] = [];
        const result: any = response.Result;
        for (var i = 0; i < result.length; i++) {
          const cAuthOption = new ConnectionAuthenticationOptions();
          cAuthOption.ConnectionTypeName = result[i].ConnectionType;
          cAuthOption.AuthType = result[i].AuthType;
          const userCredentials: any[] = result[i].UserCredentials;
          if (userCredentials != undefined) {
            for (var j = 0; j < userCredentials.length; j++) {
              const credVal = userCredentials[j].Credentials;
              cAuthOption.Credentials.push(credVal);
            }
          }
          connAuthOptions1.push(cAuthOption);
        }
        dispatch(fetchAuthenticationOptionsSuccessAction(connAuthOptions1));
      }
    })
    .catch ((error) => {
      let tempResult: IMsgInfo = {};
      tempResult.Result = 0;
      tempResult.ErrorMessage = "Unable to fetch authentication options."
      dispatch(fetchFailureAction(tempResult));
    });
  };
};

export const saveConnectionToDB = (
  newConnState: INewConnectionState,
  startGetAllConns: any
) => {
  return (dispatch: any) => {
    let conn: IConnectionAPI = {};
    getConnInfo(conn, newConnState);
    saveConnectionToDBAPI(conn)
    .then((response) => {
      const result: IMsgInfo = response;
      let tempResult: IMsgInfo = {};
      if (result != null && result !== undefined) {
        tempResult.StatusCode = result.StatusCode;
        tempResult.Result = 1;
        tempResult.ErrorMessage = "Connection saved successfully.";
        dispatch(fetchSaveSuccessAction(tempResult));
        if (startGetAllConns && result !== undefined)
          startGetAllConns();
      }
    })
    .catch((error) => {
      let tempResult: IMsgInfo = {};
      tempResult.Result = 0;
      tempResult.ErrorMessage = "Connection not saved successfully."
      dispatch(fetchFailureAction(tempResult));
    });
  };
};

export const clearValidatePropMsgDetails = (loading: boolean) => {
    return (dispatch: any) => {
        var msgInfo : IMsgInfo = {
            StatusCode: 0,
            Result: 5,
            ErrorMessage: "",
            loading: loading,
        };
        dispatch(fetchValidateCleanSuccessAction(msgInfo));
    }
}

export const clearUpdatePropMsgDetails = (
  msgInfo: IMsgInfo,
  loading: boolean
) => {
  return (dispatch: any) => {
    msgInfo.StatusCode = 0;
    msgInfo.Result = 5;
    msgInfo.ErrorMessage = "";
    msgInfo.loading = loading;
    dispatch(fetchUpdateCleanSuccessAction(msgInfo));
  };
};

export const clearSavePropMsgDetails = (
  msgInfo: IMsgInfo,
  loading: boolean
) => {
  return (dispatch: any) => {
    msgInfo.StatusCode = 0;
    msgInfo.Result = 5;
    msgInfo.ErrorMessage = "";
    msgInfo.loading = loading;
    dispatch(fetchSaveCleanSuccessAction(msgInfo));
  };
};

export const validateConnection = (newConnState: INewConnectionState) => {
  return (dispatch: any) => {
    let conn: IConnectionAPI = {};
    getConnInfo(conn, newConnState);
    validateConnectionDetails(conn)
    .then((response) => {
      const result: any = response;
      let tempResult: IMsgInfo = {};
      if (result !== undefined) {
        tempResult.StatusCode = result.StatusCode;
        tempResult.Result = 1;
        tempResult.ErrorMessage = "Connection validated successfully.";
      }
      dispatch(fetchValidateSuccessAction(tempResult));
    })
    .catch((error) => {
      let tempResult: IMsgInfo = {};
      tempResult.Result = 0;
      tempResult.ErrorMessage = "Unable to establish the connection. Validation failed."
      dispatch(fetchFailureAction(tempResult));
    });
  };
};

export const updateConnection = (
  newConnState: INewConnectionState,
  ConnectionId: number,
  startGetAllConns: any
) => {
  return (dispatch: any) => {
    let conn: IConnectionAPI = {};
    getConnInfo(conn, newConnState);
    updateConnectionDetails(conn, ConnectionId)
      .then((response) => {
        const result: any = response;
        let tempResult: IMsgInfo = {};
        if (result != undefined) {
          tempResult.StatusCode = result.StatusCode;
          tempResult.Result = 1;
          tempResult.ErrorMessage = "Connection saved successfully."
        }
        dispatch(fetchUpdateSuccessAction(tempResult));
        if (startGetAllConns && result !== undefined && result.StatusCode === 200)
        startGetAllConns();
      })
      .catch((error) => {
        let tempResult: IMsgInfo = {};
        tempResult.Result = 0;
        tempResult.ErrorMessage = "Unable to save connection."
        dispatch(fetchFailureAction(tempResult));
      });
  };
};

export const getConnInfo = (
  conn: IConnectionAPI,
  newConnState: INewConnectionState
) => {
  conn.ConnectionName = newConnState.DataSourceName;
  conn.ConnectionTypeName = newConnState.ConnectionType;
  conn.FiscalYear = newConnState.FiscalYear;
  conn.ConnectionAttributesInput = [];
  
  var userName = "";
  var pwd = "";
 
  if (newConnState.AuthenticationOption != null){
    const array = newConnState.AuthenticationOption.split(",");
    userName = array[0].split(":")[1].replace('"', "");
    pwd = array[1].split(":")[1].replace('"', "");
  }
  switch (newConnState.ConnectionType) {
    case "Azure SQL Database":
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Server Name",
        ConnectionAttributeValue: newConnState.AzureSqlConnection.ServerName,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Database Name",
        ConnectionAttributeValue: newConnState.AzureSqlConnection.DatabaseName,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Authentication Type",
        ConnectionAttributeValue: newConnState.AuthType,
        IsSecretValue: false,
        IsActive: true,
      });
      switch (newConnState.AuthType){
          case AuthenticationTypes.SqlAuth: case AuthenticationTypes.AADAuth:
              conn.ConnectionAttributesInput?.push({
                  ConnectionTypeAttributeName: "User Name",
                  ConnectionAttributeValue: userName.replace('"', ""),
                  IsSecretValue: false,
                  IsActive: true,
              });
              conn.ConnectionAttributesInput?.push({
                  ConnectionTypeAttributeName: "Password",
                  ConnectionAttributeValue: pwd.replace('"', ""),
                  IsSecretValue: true,
                  IsActive: true,
              });
              break;
          case AuthenticationTypes.ServicePrincipalAuth:
              conn.ConnectionAttributesInput?.push({
                  ConnectionTypeAttributeName: "ServicePrincipalId",
                  ConnectionAttributeValue:
                      newConnState.AzureSqlConnection.ServicePrincipalId,
                  IsSecretValue: false,
                  IsActive: true,
              });
              conn.ConnectionAttributesInput?.push({
                  ConnectionTypeAttributeName: "ServicePrincipalKey",
                  ConnectionAttributeValue:
                      newConnState.AzureSqlConnection.ServicePrincipalKey,
                  IsSecretValue: true,
                  IsActive: true,
              });
              break;
      }
      break;
    case "SQL Server":
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Server Name",
        ConnectionAttributeValue: newConnState.SqlServerConnection.ServerName,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Database Name",
        ConnectionAttributeValue: newConnState.SqlServerConnection.DatabaseName,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Authentication Type",
        ConnectionAttributeValue: newConnState.AuthType,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "User Name",
        ConnectionAttributeValue: userName.replace('"', ""),
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Password",
        ConnectionAttributeValue: pwd.replace('"', ""),
        IsSecretValue: true,
        IsActive: true,
      });
      break;
    case "Azure Data Lake Storage Gen2":
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Lake Storage Account Name",
        ConnectionAttributeValue:
          newConnState.AzureDataLakeStorageGen2.LakeStorageAccountName,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Container Name",
        ConnectionAttributeValue:
          newConnState.AzureDataLakeStorageGen2.ContainerName,
        IsSecretValue: false,
        IsActive: true,
      });
       conn.ConnectionAttributesInput?.push({
         ConnectionTypeAttributeName: "Authentication Method",
         ConnectionAttributeValue: newConnState.AuthType,
         IsSecretValue: false,
         IsActive: true,
        });
      if (newConnState.AuthType === AuthenticationTypes.ServicePrincipalAuth) {
        conn.ConnectionAttributesInput?.push({
            ConnectionTypeAttributeName: "ServicePrincipalId",
            ConnectionAttributeValue:
                newConnState.AzureDataLakeStorageGen2.ServicePrincipalId,
            IsSecretValue: false,
            IsActive: true,
        });
        conn.ConnectionAttributesInput?.push({
            ConnectionTypeAttributeName: "ServicePrincipalKey",
            ConnectionAttributeValue:
                newConnState.AzureDataLakeStorageGen2.ServicePrincipalKey,
            IsSecretValue: true,
            IsActive: true,
         });
       };
      break;
    case "File System":
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "File Share Path",
        ConnectionAttributeValue: newConnState.FileSystemConnection.FilePath,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Host",
        ConnectionAttributeValue: newConnState.FileSystemConnection.FilePath,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "User Name",
        ConnectionAttributeValue: userName.replace('"', ""),
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Password",
        ConnectionAttributeValue: pwd.replace('"', ""),
        IsSecretValue: true,
        IsActive: true,
      });
      break;
    case "Excel":
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "FileName",
        ConnectionAttributeValue: newConnState.ExcelConnection.FileName,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "SheetName",
        ConnectionAttributeValue: newConnState.ExcelConnection.SheetName,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "FirstRowAsHeader",
        ConnectionAttributeValue:
          newConnState.ExcelConnection.FirstRowAsHeader === true
            ? "true"
            : "false",
        IsSecretValue: false,
        IsActive: true,
      });
       conn.ConnectionAttributesInput?.push({
          ConnectionTypeAttributeName: "row delimeter",
          ConnectionAttributeValue: newConnState.ExcelConnection.RowDelimeter,
          IsSecretValue: false,
          IsActive: true,
        });
        conn.ConnectionAttributesInput?.push({
          ConnectionTypeAttributeName: "column delimeter",
          ConnectionAttributeValue: newConnState.ExcelConnection.ColumnDelimeter,
          IsSecretValue: false,
          IsActive: true,
        });
        conn.ConnectionAttributesInput?.push({
          ConnectionTypeAttributeName: "Lake Storage Account Name",
          ConnectionAttributeValue: newConnState.ExcelConnection.LakeStorageAccountName,
          IsSecretValue: false,
          IsActive: true,
        });
        conn.ConnectionAttributesInput?.push({
          ConnectionTypeAttributeName: "Directory",
          ConnectionAttributeValue: newConnState.ExcelConnection.Directory,
          IsSecretValue: false,
          IsActive: true,
        });
        conn.ConnectionAttributesInput?.push({
            ConnectionTypeAttributeName: "Authentication Method",
            ConnectionAttributeValue: newConnState.AuthType,
            IsSecretValue: false,
            IsActive: true,
        });
        if (newConnState.AuthType === AuthenticationTypes.ServicePrincipalAuth) {
            conn.ConnectionAttributesInput?.push({
              ConnectionTypeAttributeName: "ServicePrincipalId",
              ConnectionAttributeValue: newConnState.ExcelConnection.ServicePrincipalId,
              IsSecretValue: false,
              IsActive: true,
            });
            conn.ConnectionAttributesInput?.push({
              ConnectionTypeAttributeName: "ServicePrincipalKey",
              ConnectionAttributeValue: newConnState.ExcelConnection.ServicePrincipalKey,
              IsSecretValue: true,
              IsActive: true,
            });
          }
      break;
    case "Spark":
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Domain",
        ConnectionAttributeValue: newConnState.SparkConnection.Domain,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Existing Cluster ID",
        ConnectionAttributeValue: newConnState.SparkConnection.ClusterID,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Access Token",
        ConnectionAttributeValue: newConnState.SparkConnection.AccessToken,
        IsSecretValue: true,
        IsActive: true,
      });
      break;
    case "JAR":
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Domain",
        ConnectionAttributeValue: newConnState.JarConnection.Domain,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Access Token",
        ConnectionAttributeValue: newConnState.JarConnection.AccessToken,
        IsSecretValue: true,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "NewClusterNodeType",
        ConnectionAttributeValue: newConnState.JarConnection.NewClusterNodeType,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "NewClusterDriverNodeType",
        ConnectionAttributeValue: newConnState.JarConnection.NewClusterDriverNodeType,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "NewClusterNumOfWorker",
        ConnectionAttributeValue: newConnState.JarConnection.NewClusterNumOfWorker,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "NewClusterVersion",
        ConnectionAttributeValue: newConnState.JarConnection.NewClusterVersion,
        IsSecretValue: false,
        IsActive: true,
      });
      break;
    case "Synapse Workspace":
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Workspace URL",
        ConnectionAttributeValue: newConnState.SynapseConnection.WorkspaceURL,
        IsSecretValue: false,
        IsActive: true,
      });
      break;
    case "parquet":
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Lake Storage Account Name",
        ConnectionAttributeValue: newConnState.ParquetConnection.LakeStorageAccountName,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Container Name",
        ConnectionAttributeValue: newConnState.ParquetConnection.ContainerName,
        IsSecretValue: false,
        IsActive
        : true,
      });
      conn.ConnectionAttributesInput?.push({
          ConnectionTypeAttributeName: "Authentication Method",
          ConnectionAttributeValue: newConnState.AuthType,
          IsSecretValue: false,
          IsActive: true,
      });
      if (newConnState.AuthType === AuthenticationTypes.ServicePrincipalAuth) {
          conn.ConnectionAttributesInput?.push({
              ConnectionTypeAttributeName: "ServicePrincipalId",
              ConnectionAttributeValue: newConnState.ParquetConnection.ServicePrincipalId,
              IsSecretValue: false,
              IsActive: true,
          });
          conn.ConnectionAttributesInput?.push({
              ConnectionTypeAttributeName: "ServicePrincipalKey",
              ConnectionAttributeValue: newConnState.ParquetConnection.ServicePrincipalKey,
              IsSecretValue: true,
              IsActive: true,
          });
      }
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "folderpath",
        ConnectionAttributeValue: newConnState.ParquetConnection.folderpath,
        IsSecretValue: false,
        IsActive: true,
      });
      break;
    case "Azure Function":
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Function App URL",
        ConnectionAttributeValue: newConnState.AzureFunctionConnection.FunctionAppURL,
        IsSecretValue: false,
        IsActive: true,
      });
      conn.ConnectionAttributesInput?.push({
        ConnectionTypeAttributeName: "Function Key",
        ConnectionAttributeValue: newConnState.AzureFunctionConnection.FunctionKey,
        IsSecretValue: true,
        IsActive: true,
      });
      break;
    default:
      break;
  }
  return conn;
};
